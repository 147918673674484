.App {
  text-align: center;
  width: 600px;
  margin: 0 auto;

}
.btn-color {
  background-color: #5b8e0b !important;
  color: white !important;
}
/* .hading_color{
    background-color: #5B8E0B!important;
    color:white !important;
} */
.form {
  background-color: white;
  width: 70%;
  margin: auto;
  padding: 10px;
  /* border: 1px solid black; */
  padding-top: 40px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 10px;
}
.add-btn {
  background-color: #ffa500 !important;
  color: white !important;
  padding: 10px;
  width: 10%;

  /* margin-left: 500px; */
}
.action-btn {
  width: auto;
  padding: 0px 6px;
  background-color: #ffa500 !important;
  color: white !important;
}

.upload-btn {
  background-color: #ffa500 !important;
  width: 100%;
  font-size: 16px;
  padding: 13px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.css-mhc70k-MuiGrid-root > .MuiGrid-item {
  padding-left: 20px;
  padding-right: 20px;
}
.box {
  background: white;
  border-radius: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 38px 20px;
}
.text {
  font-size: 24px;
  color: #5b8e0b;
  padding: 38px 20px;
  text-align: center;
}

.input-box {
  /* font-size: 20px; */
  margin-bottom: 10px;
  text-align: end;
}
.select-box {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
}

.select-box button {
  width: 100%;
}

.input-box p {
  display: none;
}

.download-csv button:hover{
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: none !important;
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 50px;
  color: #649C0D;
}
.pagination {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}
.boxes {
  display: flex;

  gap: 10px;
  margin-top: 10px;
}
.pagination button {
  min-width: 30px;
}
.export_file_btn{ text-align: end;}
.export_file_btn button{
  background-color: #219d52;
  border: none;
  color: white;
  font-weight: 500;
  padding: 5px 20px;
  border-radius: 5px;
}

body{background:black; font-size:50px}
  
/* START FROM HERE */

[data-star] {
  text-align:left;
  font-style:normal;
  display:inline-block;
  position: relative;
  unicode-bidi: bidi-override;
}
[data-star]::before { 
  display:block;
  content: '★★★★★';
  color: #eee;
}
[data-star]::after {
  white-space:nowrap;
  position:absolute;
  top:0;
  left:0;
  content: '★★★★★';
  width: 0;
  color: #ff8c00;
  overflow:hidden;
  height:100%;
}

[data-star^="0.1"]::after{width:2%}
[data-star^="0.2"]::after{width:4%}
[data-star^="0.3"]::after{width:6%}
[data-star^="0.4"]::after{width:8%}
[data-star^="0.5"]::after{width:10%}
[data-star^="0.6"]::after{width:12%}
[data-star^="0.7"]::after{width:14%}
[data-star^="0.8"]::after{width:16%}
[data-star^="0.9"]::after{width:18%}
[data-star^="1"]::after{width:20%}
[data-star^="1.1"]::after{width:22%}
[data-star^="1.2"]::after{width:24%}
[data-star^="1.3"]::after{width:26%}
[data-star^="1.4"]::after{width:28%}
[data-star^="1.5"]::after{width:30%}
[data-star^="1.6"]::after{width:32%}
[data-star^="1.7"]::after{width:34%}
[data-star^="1.8"]::after{width:36%}
[data-star^="1.9"]::after{width:38%}
[data-star^="2"]::after{width:40%}
[data-star^="2.1"]::after{width:42%}
[data-star^="2.2"]::after{width:44%}
[data-star^="2.3"]::after{width:46%}
[data-star^="2.4"]::after{width:48%}
[data-star^="2.5"]::after{width:50%}
[data-star^="2.6"]::after{width:52%}
[data-star^="2.7"]::after{width:54%}
[data-star^="2.8"]::after{width:56%}
[data-star^="2.9"]::after{width:58%}
[data-star^="3"]::after{width:60%}
[data-star^="3.1"]::after{width:62%}
[data-star^="3.2"]::after{width:64%}
[data-star^="3.3"]::after{width:66%}
[data-star^="3.4"]::after{width:68%}
[data-star^="3.5"]::after{width:70%}
[data-star^="3.6"]::after{width:72%}
[data-star^="3.7"]::after{width:74%}
[data-star^="3.8"]::after{width:76%}
[data-star^="3.9"]::after{width:78%}
[data-star^="4"]::after{width:80%}
[data-star^="4.1"]::after{width:82%}
[data-star^="4.2"]::after{width:84%}
[data-star^="4.3"]::after{width:86%}
[data-star^="4.4"]::after{width:88%}
[data-star^="4.5"]::after{width:90%}
[data-star^="4.6"]::after{width:92%}
[data-star^="4.7"]::after{width:94%}
[data-star^="4.8"]::after{width:96%}
[data-star^="4.9"]::after{width:98%}
[data-star^="5"]::after{width:100%}